<template>
    <div class="flex flex-row justify-center w-full">

        <claims-action />
    </div>
</template>

<script>

import ClaimsAction from "../components/claims-action/ClaimMain.vue"
export default {
    components: {
        ClaimsAction,

    },
    data() {
        return {

        };
    },
    watch: {


    },
    created() {

    },
    methods: {

    },
};
</script>


