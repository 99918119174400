<template>
    <b-card class="bg-white rounded-lg p-3 mt-5">
        <b-container fluid>
            <!-- User Interface controls -->
            <b-row>
                <b-col lg="6" class="my-1">
                    <b-form-group v-slot="{ ariaDescribedby }" label="Sort" label-for="sort-by-select" label-cols-sm="3"
                        label-align-sm="right" label-size="sm" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-select id="sort-by-select" v-model="sortBy" :options="sortOptions"
                                :aria-describedby="ariaDescribedby" class="w-75">
                                <template #first>
                                    <option value="">-- none --</option>
                                </template>
                            </b-form-select>

                            <b-form-select v-model="sortDesc" :disabled="!sortBy" :aria-describedby="ariaDescribedby"
                                size="sm" class="w-25">
                                <option :value="false">Asc</option>
                                <option :value="true">Desc</option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col lg="6" class="my-1">

                </b-col>



                <b-col lg="12" class="my-1">
                    <b-form-group v-slot="{ ariaDescribedby }" v-model="sortDirection" label="Filter By Stage"
                        description="Select all to view all stages" label-cols-sm="3" label-align-sm="right"
                        label-size="sm" class="mb-0">
                        <b-form-checkbox-group v-model="filterOn" :aria-describedby="ariaDescribedby" class="mt-1">
                            <b-form-checkbox value="visit">Visit Only</b-form-checkbox>
                            <b-form-checkbox value="creditor">Creditor Details</b-form-checkbox>
                            <b-form-checkbox value="claim">Claim Details</b-form-checkbox>
                            <b-form-checkbox value="review">Reviewing</b-form-checkbox>
                            <b-form-checkbox value="filed">Filed</b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>

                <b-col sm="5" md="6" class="my-1">
                    <b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
                        label-cols-lg="3" label-align-sm="right" label-size="sm" class="mb-0">
                        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"
                            size="sm"></b-form-select>
                    </b-form-group>
                </b-col>

                <b-col sm="7" md="6" class="my-1">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
                        size="sm" class="my-0"></b-pagination>
                </b-col>
            </b-row>

            <!-- Main table element -->
            <b-table no-local-sorting :busy.sync="isBusy" :items="backendCall" :fields="fields"
                :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md"
                show-empty small @filtered="onFiltered" @sort-changed="onFiltered">
                <template #cell(caseNumber)="row">
                    <b-button size="sm" @click="onRowSelected(row, row.index, $event.target)">
                        View Case
                    </b-button>
                </template>

                <template #cell(visits)="row">

                        <b-button size="sm" @click="row.toggleDetails">
                            View Visits
                        </b-button>
                </template>

                <template #row-details="row">
                    <b-card class="rounded border">

                        <b-table striped hover :fields="visitFields" :items="row.item.visits"></b-table>
                    </b-card>
                </template>
            </b-table>

            <!-- Info modal -->
            <b-modal :id="infoModal.id" :title="infoModal.title" ok-only size="xl"
            :busy="busyModal"
        scrollable
        header-class="bg-white"
        hide-footer  @hide="resetInfoModal">
                <pre><total-case-view v-if="infoModal.content" :casedata="infoModal.content" v-on="$listeners">
        </total-case-view></pre>
            </b-modal>
        </b-container>
    </b-card>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,

    BFormCheckboxGroup,
    BFormCheckbox,
    BPagination,
    BTable,
    BButton,
    BModal,
    BCard
} from "bootstrap-vue"
import TotalCaseView from "@/views/components/total-case-view/TotalCaseView.vue";
export default {
    components: {
        TotalCaseView,
        BContainer,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BInputGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BPagination,
        BTable,
        BButton,
        BModal,
        BCard
    },
    data() {
        return {
            items: [
            ],
            fields: [
                { key: 'caseNumber', label: 'Case', sortable: true, sortDirection: 'desc' },
                { key: 'creditorName', label: 'Creditor', sortable: true, sortDirection: 'desc' },
                { key: 'stage', label: 'Stage', sortable: true, class: 'text-center' },
                { key: 'visits', label: 'Visits', sortable: true, class: 'text-center' },
                { key: 'download', label: 'view / download', sortable: true, class: 'text-center' },

            ],
            visitFields: [
                { key: 'visit_date', label: 'Visit Date' },
                { key: 'ip_address', label: 'IP', },
                { key: 'country', label: 'Country', },
                { key: 'state', label: 'State', },
                { key: 'city', label: 'City', },
                { key: 'device', label: 'Device', },
                { key: 'lat_lng', label: 'Lat, Lng', },

            ],
            busyModal: false,
            isBusy: false,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortBy: 'Stage',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: ['visit', 'creditor', 'claim', 'review', 'filed'],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: null
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        // Set the initial number of items

        this.totalRows = this.items.length
    },
    methods: {
        backendCall(ctx, callback) {
            const l1 = this

            var x = (ctx.currentPage - 1) * this.perPage
            if (x < 0) {
                x = x - 1
            }
            ctx["limit"] = ctx.perPage
            ctx["offset"] = x



            l1.$AuthAxios.instance
                .get("/api-access/search/claims-action/claims-main/", {
                    params: ctx
                })
                .then(function (res) {

                    l1.totalRows = res.data.count
                    callback(res.data.results)
                })
                .catch((error) => {
                    console.log(error)
                })
            return null

        },
        onRowSelected(setData, index, button) {
            var data = setData.item;

            var l1 = this;
            l1.busyModal = true
            l1.infoModal.title = `Row index: ${index}`
            l1.$root.$emit('bv::show::modal', l1.infoModal.id, button)
            l1.$AuthAxios.instance
                .get("/api-access/search/case-search/", {
                    params: {
                        case: data.caseNumber,
                    },
                })
                .then((res) => {

                    var data = res.data.results[0];
                    data["index"] = 0;
                    data["table_model"] = { key: "case_of_party", label: "Details" };
                    data["index"] = setData.index;
                    data["tableName"] = 'Claims';



                    
                    l1.infoModal.content = data
                    
                    l1.busyModal = false


                })
                .catch((error) => {
                    l1.isBusy = false;

                    console.log("verify-user endpoint ERROR ", error);

                    throw new Error({ error: error });
                });
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    }
}
</script>